<template>
  <div class="dm_landing_bg">
    <!-- Navigation -->
    <nav class="navbar navbar-expand-lg static-top">
      <div class="container">
        <a class="navbar-brand" href="#"><img class="dm_logo" src="../../assets/img/logo.png" alt=""></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
          aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/privacy-policy">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/terms-of-use">Terms Of Use</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Page Content -->
    <div class="h-100">
      <div class="container">
        <div class="row">
          <div class="col-md-6 offset-md-6">
            <h1>Welcome!</h1>
            <p>Doggymate is a user friendly space for dog lovers all around the world. We serve many needs. Looking to find a mate for your dog? A breeder looking to expand your beloved breed or  perhaps you’re amongst millions of dog lovers trying to get a bigger glance at beautiful dogs globally. Join us, create an account for your pet(s) and showcase your dog to the world! This is the new premier social network for dogs and dog lovers!</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 offset-md-6">
            <button class="dm_btn_med_solid mr-4" data-toggle="modal" data-target="#signupModal">Sign Up</button>
            <button class="dm_btn_dark_outline" data-toggle="modal" data-target="#signinModal">Sign In</button>
          </div>
        </div>

        <!-- Modal 1 -->
        <div class="modal fade dm_reg_modal_wrapper" id="signinModal" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content border-0">
              <div class="row justify-content-center">
                <div class="col-md-8 col-12">
                  <div class="modal-header justify-content-center border-0 px-md-0 px-3">
                    <img class="dm_logo" src="../../assets/img/logo.png" alt="">
                  </div>
                  <div class="modal-body px-md-0 px-3">
                    <h2 class="mb-4 text-primary">Sign In</h2>

                    <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-user"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model='login_email' placeholder="Please enter your email/username!"
                          aria-label="Username" aria-describedby="basic-addon1">
                      </div>
                      <div class="error_msg alert" v-if="errors.login_email">
                          <strong>{{ errors.login_email }}</strong>
                      </div>
                    </div>

                    <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-key"></i></span>
                        </div>
                        <input type="password" v-model='login_password' class="form-control" placeholder="Please enter your password!"
                          aria-label="Password" aria-describedby="basic-addon1">
                      </div>
                      <div class="error_msg alert" v-if="errors.login_password">
                          <strong>{{ errors.login_password }}</strong>
                      </div>
                    </div>


                  </div>
                  <div class="modal-footer border-0 pb-4 px-md-0 px-3">
                    <div class="row">
                      <div class="col-12">
                        <div class="dm_btn_wraper d-flex justify-content-between align-items-center">
                          <button type="button" @click="checkLoginForm"
                            class="dm_btn_med_solid py-3">Sign In</button>
                            <button type="button" class="dm_btn_dark_outline py-3" data-dismiss="modal">Cancel</button>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-5">
                        <span class="text-center dm_text_dark_med_sm">Don't have an account? <a
                            data-toggle="modal" data-target="#signupModal" class="text-secondary">Sign Up</a></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal 2 -->

        <div class="modal fade dm_modal_wrapper" id="signupModal" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content border-0">
              <div class="row justify-content-center">
                <div class="col-md-8">

                  <div class="modal-header justify-content-center border-0 px-md-0 px-3">
                    <img class="dm_logo" src="../../assets/img/logo.png" alt="">
                  </div>

                  <div class="modal-body px-md-0 px-3">
                    <h2 class="mb-4 text-primary">Sign Up</h2>

                    <croppa v-model="myCroppa" canvas-color="transparent" :quality="1" :prevent-white-space="true" :zoom-speed="7" :width="500" :height="500" :placeholder-font-size="38">
                      <img crossOrigin="anonymous" src="../../assets/img/doggy.png" slot="initial">
                    </croppa>

                    <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-envelope"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model="email" name="email" placeholder="Please enter your email!"
                          aria-label="Email" aria-describedby="basic-addon1">
                      </div>
                      <div class="error_msg alert" v-if="errors.email">
                        <strong>{{ errors.email }}</strong>
                      </div>
                    </div>

                    <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-user"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model="username" name="username"
                          placeholder="Please enter your username!" aria-label="Username" aria-describedby="basic-addon1">
                      </div>
                      <div class="error_msg alert" v-if="errors.username">
                        <strong>{{ errors.username }}</strong>
                      </div>
                    </div>

                    <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-user"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model="first_name" name="first_name"
                          placeholder="Please enter your first name!" aria-label="first_name" aria-describedby="basic-addon1">
                      </div>
                      <div class="error_msg alert" v-if="errors.first_name">
                        <strong>{{ errors.first_name }}</strong>
                      </div>
                    </div>

                    <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-user"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model="last_name" name="last_name"
                          placeholder="Please enter your last name!" aria-label="last_name" aria-describedby="basic-addon1">
                      </div>
                      <div class="error_msg alert" v-if="errors.last_name">
                        <strong>{{ errors.last_name }}</strong>
                      </div>
                    </div>

                    <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-dog"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model="dog_name" name="dog_name"
                          placeholder="Please enter dog name!" aria-label="dog_name" aria-describedby="basic-addon1">
                      </div>
                      <div class="error_msg alert" v-if="errors.dog_name">
                        <strong>{{ errors.dog_name }}</strong>
                      </div>
                    </div>

                    <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-key"></i></span>
                        </div>
                        <input type="password" class="form-control" v-model="password" name="password"
                          placeholder="Please enter your password!" aria-label="Password" aria-describedby="basic-addon1">
                      </div>
                      <div class="error_msg alert" v-if="errors.password">
                        <strong>{{ errors.password }}</strong>
                      </div>
                    </div>

                    <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-key"></i></span>
                        </div>
                        <input type="password" class="form-control" v-model="confirm_password" name="confirm_password"
                          placeholder="Please confirm your password!" aria-label="Confirm Password"
                          aria-describedby="basic-addon1">
                      </div>
                    </div>

                   <!-- <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-user"></i></span>
                        </div>
                        <input type="file" class="form-control" name="profile_pic" placeholder="Profile picture"
                          aria-label="profile_pic" aria-describedby="basic-addon1">
                      </div>
                      <div class="error_msg alert" v-if="errors.profile_pic">
                        <strong>{{ errors.profile_pic }}</strong>
                      </div>
                    </div> -->

                    <div class="dm_error_wraper mb-4">
                      <div class="dm_reg_input input-group input-group-lg">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-dog"></i></span>
                        </div>

                        <select name="breed" class="custom-select mr-2 py-2 px-3 dm_card_shadow"
                          id="inputGroupSelect01">
                          <option selected value="">Please enter the breed of your dog!</option>
                          <option v-for="b in breeds" :key="b.id" :value="b.id">{{ b.name }}</option>
                        </select>
                      </div>
                      <div class="error_msg alert" v-if="errors.breed">
                        <strong>{{ errors.breed }}</strong>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer border-0 pb-4 px-md-0 px-3">
                    <div class="row">
                      <div class="col-12">
                        <div class="dm_btn_wraper d-flex justify-content-between align-items-center">
                          <button type="button" @click="checkRegistrationForm"
                            class="dm_btn_med_solid py-3">Sign Up</button>
                            <button type="button" class="dm_btn_dark_outline py-3" data-dismiss="modal">Cancel</button>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-check text-center mb-3 mt-4">
                          <input class="form-check-input ml-0" v-model="is_tou" type="checkbox" value=""
                            id="defaultCheck1">
                          <label class="form-check-label dm_text_dark_med_sm ml-3" for="defaultCheck1">
                            By proceeding, I agree to Doggymate’s <a href="/terms-of-use">Terms of Use</a> and acknowledge that I have read the <a href="/privacy-policy">Privacy Policy</a>
                          </label>
                        </div>
                      </div>
                      <div class="col-12 text-center">
                        <span class="text-center dm_text_dark_med_sm">Already have an account? <a
                            data-toggle="modal" data-target="#signinModal" class="text-secondary">Sign In</a></span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>

  </div>

</template>

<style>
  .croppa-container {
    background-color: white;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    border: none;
    border-radius: 200px !important;
    padding-bottom: 30px;
  }
  .croppa-container canvas {
    width: 200px !important;
    height: 200px !important;
    border-radius: 200px !important;
    box-shadow: 0px 15px 30px #00000029;
  }
  .croppa-container svg {
    height: 30px;
    width: 30px;
    top: -8px !important;
    right: 200px !important;
    border: #634E55 1px solid !important;
    background: #634E55 !important;
    box-shadow: 0px 3px 6px #00000029 !important;
  }
  .croppa-container svg path {
    fill: #fff !important;
  }
</style>

<script>


import Vue from 'vue';
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';

Vue.use(Croppa);
Vue.component('croppa', Croppa.component);

export default {
    name: "landing",
    data: () => ({
     login_email: '',
     login_password: '',
     email: '',
     username: '',
     password: '',
     first_name: '',
     last_name: '',
     dog_name: '',
     profile_pic: '',
     confirm_password: '',
     breeds: [],
     breed: '',
     is_tou: false,
     myCroppa: null,
     errors:{},
     reg: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
    }),
    components: {
    },
    methods: {
      checkRegistrationForm: function (e) {
        this.generateImage();
        this.errors = [];

        if(this.email == null || this.email == '')
        {
          this.errors.email = "Please Enter Email";
        }
        else if(!this.email.match(this.reg))
        {
          this.errors.email = "Please Enter Correct Email";
        }
        
        if (!this.username) {
          this.errors.username = 'User name is required.';
        }
        if (!this.first_name) {
          this.errors.first_name = 'First name is required.';
        }
        if (!this.last_name) {
          this.errors.last_name = 'Last name is required.';
        }
        if (!this.dog_name) {
          this.errors.dog_name = 'Dog name is required.';
        }
        // if (!this.profile_pic) {
        //   this.errors.profile_pic = 'Profile picture is required.';
        // }
        if (!this.password) {
          this.errors.password = 'Password is required.';
        }
        this.breed = window.parseInt(window.$("[name=breed]").select2('val'));
        if (!this.breed && this.breed != '') {
          this.errors.breed = 'Breed name is required.';
        }
        if (this.password && this.password != this.confirm_password) {
          this.errors.password = 'Confirm password does not match.';
        }
        if(this.is_tou != true){
          window.notify(false, 'Please agree to Doggymate’s Terms of Use & Privacy Policy.');
          return false;
        }
        if(Object.keys(this.errors) == 0){
          this.submitRegistration();
        }
        e.preventDefault();
      },
      checkLoginForm: function (e) {
        this.errors = [];

        if (!this.login_email) {
          this.errors.login_email = 'Email is required.';
        }
        if (!this.login_password) {
          this.errors.login_password = 'Password is required.';
        }

        if(Object.keys(this.errors) == 0){
          this.submitLogin();
        }
        e.preventDefault();
      },
      // validateEmail: function(mail) 
      // {
      //   var mailformat = "/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/";
      //   if(mail.value.match(mailformat)){
      //     alert();
      //     return true;
      //   }
      //   return false;
      // },
      resetValues: function () {
        this.email = '';
        this.login_email = '';
        this.login_password = '';
        this.email = '';
        this.username = '';
        this.password = '';
        this.confirm_password = '';
        this.breed = '';
        this.first_name = '';
        this.last_name = '';
        this.dog_name = '';
        this.is_tou = false;
        window.$("[name=breed]").select2('val',"");
      },
      async submitRegistration() {
        let form = {
          username: this.username,
          email: this.email,
          breed: this.breed,
          first_name: this.first_name,
          last_name: this.last_name,
          dog_name: this.dog_name,
          profile_pic: this.profile_pic,
          password: this.password,
        };
        let responce = await this.$store.dispatch("submitRegistrationForm", form);

        window.notify(responce.data.status, responce.data.message);
        if (responce.data.status == true) {
          window.$("#signupModal").modal('hide');
          window.$("#signinModal").modal('show');
          this.resetValues();
        }
        return false;
      },
      async submitLogin() {
        let form = {
          email: this.login_email,
          password: this.login_password
        };

        let responce = await this.$store.dispatch("submitLoginForm", form);
        if (responce.data.status) {
          window.notify(responce.data.status, responce.data.message);
          window.$('.modal-backdrop').remove();
          if(typeof localStorage.getItem('is_mobile') != 'undefined' && localStorage.getItem('is_mobile') == 'true'){
            this.$router.replace({ name: "subscription" });
            localStorage.setItem("is_mobile", false);
          }else{
            this.$router.replace({ name: "posts" });
          }
          // this.$router.push("/home");
        }
      },
      async getBreedList() {
        let responce = await this.$store.dispatch("getBreedList");
        if(responce.status){
            this.breeds = responce.data.data;

            window.$("[name=breed]").select2({
                dropdownParent: window.$('#signupModal .modal-content')
            });
        }
      },
      generateImage: function() {
        let url = this.myCroppa.generateDataUrl()
        if (!url) {
          this.profile_pic = '';
          // alert('no image')
          // return
        }
        else
        {
          this.profile_pic = url;
        }
      }
    },
    created() {
      this.getBreedList();
    }
};
</script>
